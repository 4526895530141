@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  width: 100%;
  overflow-x: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
}

*,
::before,
::after {
  border-width: 0;
}

/* In your tailwind.css file */
.diagonal-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  @apply bg-floralwhite transform -skew-y-3;
}